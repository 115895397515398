import React from 'react';
import Progress from '../common/Progress';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import { correctHeight, detectBody } from './Helpers';
import pubsub from 'pubsub-js';
import $ from 'jquery';

class MainLayout extends React.Component {
    constructor() {
        super();
        let me = this;
        pubsub.subscribe('APPLICATION_EVENT', function (topic, data) {
            console.log(data);
            me.props.children.props.history.push('/'+ data);
        });
    }

    render() {
        let wrapperClass = "gray-bg ";
        return (
            <div id="wrapper">
                <Progress />
                <Navigation currentLocation={this.props.children.props.history.location.pathname} />
                <div id="page-wrapper" className={wrapperClass}>
                    <TopHeader />
                    {this.props.children}
                    <Footer />
                </div>
            </div>
        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function () {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        $('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300)
        });
    }
}

export default MainLayout