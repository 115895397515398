import React, { Component } from 'react';
import BoundInput from '../components/common/BoundInput';
import BoundSelect from '../components/common/BoundSelect';
import BoundCheckboxListView from '../components/common/BoundCheckboxListView';

import UserStore from '../stores/UserStore';
import AuthStore from '../stores/AuthStore';
import CustomerStore from '../stores/CustomerStore';
import SweetAlert from 'react-bootstrap-sweetalert';


class SaveUser extends Component {
    constructor() {
        super();
        this.state = {
            draftUser: { username: '', password: '', reenter_password: '', default_customer: null, customers: [], full_name: '', profilePic: 'profile.jpg', type: 'user', ui_permissions: [] },
            draftDevice: {},
            deviceMode: 'create',
            mode: 'create',
            error: '',
            showDelete: false,
            userTypes: [],
            currentCustomer: AuthStore.getCustomer(),
            customers: [],
            uiPermissions: [],
            renderPasswordInEditMode: false
        }
    }
    getCustomerDevices() {
        let devices = this.state.currentCustomer.devices.map(d => Object.assign({ "internalValue": d.id, "displayValue": d.id }));
        return devices;
    }
    componentWillMount() {
        let uiPermissions = AuthStore.UIPermissions(this.state.draftUser.type);
        this.setState({ uiPermissions: uiPermissions })

        const username = this.props.match.params.username;
        UserStore.userTypes(AuthStore.getCustomer().c_id, AuthStore.getUser().type).then(data => {
            this.setState({ userTypes: data });
        })

        CustomerStore.fetchAllCustomer(AuthStore.getUser()).then(data => {
            this.setState({ customers: data });
        })

        if (username !== 'create') {
            UserStore.fetchUserById(this.state.currentCustomer.c_id, username)
                .then((user) => {
                    console.log(user);
                    this.setState({ draftUser: user, mode: 'edit' });
                }).catch((err) => {
                    this.setState({ error: err.message });
                });
        } else {
            let user = this.state.draftUser;
            user.default_customer = AuthStore.getCustomer().c_id;
            let customer = Object.assign({}, AuthStore.getCustomer());
            customer.devices = [];
            user.customers.push(customer);
            this.setState({ mode: 'create', draftUser: user });
        }
    }

    onSaveHandler(e) {
        if (e) {
            e.preventDefault();
        }

        let hasErrors = false;
        if (this.state.mode === 'create' || this.state.renderPasswordInEditMode) {
            if (this.state.draftUser.password !== this.state.draftUser.reenter_password) {
                hasErrors = true;
                this.setState({ error: "Passwords don't match" });
            }
        }

        if (!hasErrors) {
            UserStore.updateUser(this.state.currentCustomer.c_id, this.state.draftUser, this.state.mode).then(data => {
                this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
                this.props.history.push('/users');
            }).catch(err => {
                this.setState({ error: err.message });
            });

        }
    }

    onCancelHandler() {
        this.props.history.push('/users');
    }

    onSaveDeviceHandler(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('saving device info');
        //CustomerStore.saveCustomer(this.state.draftCustomer, 'update');
        this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
    }

    onCancelDeviceHandler(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
    }


    renderError() {
        if (this.state.error) {
            return (
                <div className="error">{this.state.error}</div>
            );
        }
    }

    onDeleteHandler(device, e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ showDelete: true, draftDevice: device });
    }

    onEditHandler(device, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ draftDevice: device, deviceMode: 'edit' });
    }

    renderEdit(cell, record) {
        return <button className="btn btn-info fa fa-edit" onClick={this.onEditHandler.bind(this, record)}></button>;
    }

    renderDelete(cell, record) {
        return <button className="btn btn-danger fa fa-trash" onClick={this.onDeleteHandler.bind(this, record)}></button>;
    }


    showDeleteRender() {
        if (this.state.showDelete) {
            let user = this.state.draftUser;
            return <SweetAlert
                danger
                showCancel
                confirmBtnText={"Yes, delete it !"}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={"Delete user: " + user.username + " ?"}
                onConfirm={this.deleteUserHandler.bind(this)}
                onCancel={this.cancelDeleteDeviceHandler.bind(this)}
            >
                You will not be able to undo this action!
        </SweetAlert>
        }
    }

    deleteDeviceHandler(e) {
        console.log('delete handler')

        this.setState({ showDelete: false });
    }


    cancelDeleteDeviceHandler() {
        this.setState({ showDelete: false });
    }

    onDevicesChange(c_id, deviceId, e) {
        let val = e.target.checked;
        let currentUser = this.state.draftUser;
        let customerInCurrentUser = currentUser.customers.find(c => c.c_id === c_id);

        if (!customerInCurrentUser) {
            currentUser.customers.push({ c_id: c_id, devices: [] });
        }

        //then update the user with device Id
        if (customerInCurrentUser && customerInCurrentUser.devices) {
            if (val) {
                let deviceInCurrentUser = customerInCurrentUser.devices.find(d => d.id === deviceId);
                if (!deviceInCurrentUser) {
                    customerInCurrentUser.devices.push({ id: deviceId });
                }
            }
            else {
                let deviceIndex = customerInCurrentUser.devices.findIndex(d => d.id === deviceId);
                if (deviceIndex !== -1) {
                    customerInCurrentUser.devices.splice(deviceIndex, 1);
                }
            }
        }
        this.setState({ draftUser: currentUser })
    }

    checkCustomerDevices(c_id, deviceId) {
        let currentUser = this.state.draftUser;
        let customerInCurrentUser = currentUser.customers.find(cust => cust.c_id === c_id);
        if (customerInCurrentUser) {
            //then update the user with device Id
            if (customerInCurrentUser && customerInCurrentUser.devices) {
                return customerInCurrentUser.devices.find(d => d.id === deviceId) !== undefined;
            }
        }
        return false;
    }

    renderPassword() {
        let showPasswordFields = this.state.mode === 'create' || this.state.renderPasswordInEditMode;
        if (showPasswordFields) {
            return (
                <div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Password</label>
                        <div className="col-sm-8">
                            <BoundInput
                                type="password"
                                store={this.state.draftUser}
                                mode='create'
                                placeholder="Password"
                                jsonPath="password"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Re Enter Password</label>
                        <div className="col-sm-8">
                            <BoundInput
                                type="password"
                                store={this.state.draftUser}
                                mode='create'
                                placeholder="Re-enter Password"
                                jsonPath="reenter_password"
                            />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.mode === 'edit') {
            return (
                <div className="form-group">
                    <label className="col-sm-4 control-label">Reset Password</label>
                    <div className="col-sm-8">
                        <button className="btn btn-info" onClick={this.resetPassword.bind(this)}>Reset Password</button>
                    </div>
                </div>
            );
        }
        return null;
    }

    resetPassword() {
        this.setState({ renderPasswordInEditMode: true });
    }

    userTypeChanged(val) {
        let uiPermissions = AuthStore.UIPermissions(this.state.draftUser.type);

        //make sure the draft user has no permissions that are not in the new list
        let user = this.state.draftUser;
        let deletePermissionList = [];
        user.ui_permissions.forEach(permission => {
            let permissionIndex = uiPermissions.findIndex(p => p.internalValue === permission);
            if (permissionIndex === -1) {
                deletePermissionList.push(permission);
            }
        });

        deletePermissionList.forEach(permission => {
            let permissionIndex = user.ui_permissions.findIndex(p => p === permission);
            user.ui_permissions.splice(permissionIndex, 1);
        })


        //make sure that the draft user has no customers that are not in the customers list
        let deleteCustomerList = [];
        if (this.state.currentCustomer.c_id !== '001' || this.state.draftUser.type !== 'delectrik_admin') {
            //delete all but the current customer
            //if the user has the current customer in its customers collection then leave it, otherwise add a blank current customer
            let existingCustomerDevices = [];
            let customer = user.customers.find(c => c.c_id === this.state.currentCustomer.c_id);
            if (customer) {
                existingCustomerDevices = customer.devices;
            }

            user.customers = [];
            let currentCustomerClone = Object.assign(this.state.currentCustomer);
            currentCustomerClone.devices = existingCustomerDevices;
            user.customers.push(currentCustomerClone);
        } else {
            user.customers.forEach(customer => {
                let customerIndex = this.state.customers.findIndex(c => c.c_id === customer.c_id);
                if (customerIndex === -1) {
                    deleteCustomerList.push(customer);
                }
            });
        }

        deleteCustomerList.forEach(customer => {
            let customerIndex = user.customers.find(c => c.c_id === customer.c_id);
            user.customers.splice(customerIndex, 1);
        });

        this.setState({ uiPermissions: uiPermissions, draftUser: user })
        this.forceUpdate();
    }

    render() {
        let mode = this.state.mode === 'create' ? 'Add User' : 'Update ' + this.state.draftUser.username;
        //if currentCustomer is delectrik and current user is superuser then show all devices for all customers
        let customerDevices = {};

        if (this.state.currentCustomer.c_id !== '001' || this.state.draftUser.type !== 'delectrik_admin') {
            customerDevices = this.state.currentCustomer.devices.map(d =>
                <div key={d.id}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" onChange={this.onDevicesChange.bind(this, this.state.currentCustomer.c_id, d.id)} checked={this.checkCustomerDevices(this.state.currentCustomer.c_id, d.id)} />
                            {d.id}
                        </label>
                    </div>
                </div>
            );
        }
        else {
            if (this.state.currentCustomer.c_id === '001' && this.state.draftUser.type === 'delectrik_admin' && this.state.customers && this.state.customers.length > 0) {
                customerDevices = this.state.customers.map(c =>
                    <div key={c.c_id}>
                        <label>Customer: {c.c_name} -- {c.c_id}</label>
                        {c.devices ? c.devices.map(d =>
                            <div key={d.id}>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" onChange={this.onDevicesChange.bind(this, c.c_id, d.id)} checked={this.checkCustomerDevices(c.c_id, d.id)} />
                                        {d.id}
                                    </label>
                                </div>
                            </div>
                        ) : null}
                        <br />
                    </div>
                );
            }
        }


        return (
            <div className="row">
                {this.showDeleteRender()}
                <div className="col-lg-6">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>{mode} <label className="text-danger">{this.state.error}</label></h5>
                        </div>
                        <div className="ibox-content">
                            <form method="get" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Username</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="text"
                                            store={this.state.draftUser}
                                            mode={this.state.mode === 'edit' ? 'readonly' : this.state.mode}
                                            placeholder="Username"
                                            jsonPath="username"
                                        />
                                    </div>
                                </div>
                                {this.renderPassword()}
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Full Name</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="text"
                                            store={this.state.draftUser}
                                            mode={this.state.mode}
                                            placeholder="Name"
                                            jsonPath="full_name"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">User Type</label>
                                    <div className="col-sm-8">
                                        <BoundSelect
                                            store={this.state.draftUser}
                                            options={this.state.userTypes}
                                            mode={this.state.mode}
                                            placeholder="Type"
                                            jsonPath="type"
                                            onChange={this.userTypeChanged.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">UI Permissions</label>
                                    <div className="col-sm-8">
                                        <BoundCheckboxListView
                                            store={this.state.draftUser}
                                            list={this.state.uiPermissions}
                                            mode={this.state.mode}
                                            jsonPath="ui_permissions"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Customer Devices</label>
                                    <div className="col-sm-8">
                                        {customerDevices}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-8 col-sm-offset-4">
                                        <button className="btn btn-white" onClick={this.onCancelHandler.bind(this)}>Cancel</button>
                                        <button className="btn btn-primary" onClick={this.onSaveHandler.bind(this)}>Save changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SaveUser