import React, { Component } from 'react';
import AuthStore from '../stores/AuthStore';
import UserStore from '../stores/UserStore';
import Card from '../components/common/UserCard';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';

class UserDashboard extends Component {
    constructor() {
        super();
        this.stateToken = null;
        this.socketToken = null;
        this.state = {
            showDelete: false,
            users: null
        }
    }

    componentDidMount() {
        this.loadUsers();
    }

    loadUsers() {
        let currentCustomer = AuthStore.getCustomer();
        UserStore.fetchUsers(currentCustomer.c_id, AuthStore.getUser().type).then((data) => {
            if (data) {
                this.setState({ users: data });
            }
        });
    }

    onUserClickHandler(username, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.history.push(`/users/${username}`);
    }

    onUserDeleteClickHandler(username, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ showDelete: true, username: username });
    }

    showDeleteRender(username) {
        if (this.state.showDelete) {

            return <SweetAlert
                danger
                showCancel
                confirmBtnText={"Yes, delete it !"}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={"Delete user: " + this.state.username + " ?"}
                onConfirm={this.deleteFile.bind(this)}
                onCancel={this.cancelDelete.bind(this)}
            >
                You will not be able to undo this action!
        </SweetAlert>

        }
    }

    deleteFile(e) {
        console.log(`deleting user ${this.state.username}`)
        UserStore.deleteUser(AuthStore.getCustomer().c_id, { username: this.state.username }).then(data => {
            this.setState({ showDelete: false });
            this.loadUsers();
        })

    }


    cancelDelete() {
        this.setState({ showDelete: false });
    }

    createUserLink() {
        return <Link to="/users/create" className="btn btn-primary pull-right" ><i className="fa fa-plus-square"></i></Link>;
    }


    render() {
        const userLink = this.createUserLink();
        if (this.state.users && this.state.users.length > 0) {
            let users = this.state.users.map((user) =>
                <Card
                    id={user.username}
                    key={user.username}
                    display={user.full_name}
                    profilePic={user.profilePic}
                    onClickHandler={this.onUserClickHandler.bind(this, user.username)}
                    onDeleteHandler={this.onUserDeleteClickHandler.bind(this, user.username)}
                />
            );
            return (
                <div>
                    {this.showDeleteRender()}
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            <h2>Users</h2>
                        </div>
                        <div className="col-lg-2">
                            <h2>{userLink}</h2>
                        </div>
                    </div>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="row">
                            {users}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {this.showDeleteRender()}
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>Users</h2>
                    </div>
                    <div className="col-lg-2">
                        <h2>{userLink}</h2>
                    </div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">

                    </div>
                </div>
            </div>
        );;
    }
}

export default UserDashboard