import axios from 'axios';
import Config from '../config';

//import pubsub from 'pubsub-js';



class CustomerStore {
    /**
     * fetch customer by id
     */
    fetchCustomer(c_id) {
        return axios({
            method: 'get',
            url: Config.REACT_APP_API_ROOT_URL + `/customers/${c_id}`
        }).then((res) => {
            return res.data;
        });
    }

    fetchAllCustomer(user) {
        return axios({
            method: 'post',
            url: Config.REACT_APP_API_ROOT_URL + `/customers`,
            data: user
        }).then((res) => {
            return res.data;
        });
    }


    saveCustomer(cust, operation) {
        console.log('saving customer ...')
        let queryString = `/customers/${cust.c_id}`;
        let url = Config.REACT_APP_API_ROOT_URL + queryString;
        let method = operation === 'add' ? 'post' : 'put';
        return axios({
            method: method,
            data: cust,
            url: url
        }).then((res) => {
            if (res.data.errorMessage){
                throw new Error(res.data.errorMessage);
            }
            return res.data;
        });
    }

    deleteCustomer(c_id, user){
        return axios({
            method: 'delete',
            url: Config.REACT_APP_API_ROOT_URL + `/customers/${c_id}`,
            data: user
        }).then((res) => {
            return res.data;
        }); 
    }

}

let customerStore = window.CustomerStore = new CustomerStore();
export default customerStore;