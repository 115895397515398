import React, { Component } from 'react';
import AuthStore from '../stores/AuthStore';
import BatteryLogStore from '../stores/BatteryLogStore';
import DatePicker from 'react-date-picker';
import moment from 'moment-timezone';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


class DownloadLogs extends Component {
    constructor() {
        super();
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            devices: [],
            error: null,
            downloading: false,
            overallStatus: [],
            queryServer: false,
            setIntervalToken: null
        }
    }

    componentDidMount() {
        let start = this.state.startDate;
        start.setHours(0, 0, 0);
        let end = this.state.endDate;
        end.setHours(23, 59, 59);
        this.setState({ startDate: start, endDate: end });
        this.fetchLogs();
        this.checkFileStatus();
    }

    componentWillUnmount() {
        if (this.state.setIntervalToken) {
            let { setIntervalToken } = this.state;
            clearInterval(setIntervalToken);
        }
    }

    fetchLogs() {
        let customer = AuthStore.getCustomer();
        let devices = customer.devices.map(d => Object.assign(d, { checked: false }))
        this.setState({ error: null, devices: devices });
    }

    onStartDateChange(sd) {
        if (sd) {
            sd.setHours(0, 0, 0, 0);
            this.setState({ startDate: sd });
        }
    }

    onEndDateChange(ed) {
        if (ed) {
            ed.setHours(23, 59, 59);
            this.setState({ endDate: ed });
        }
    }

    downloadHandler(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ downloading: true });
        let download_with_admin_access = true ;// AuthStore.getUser().type === 'delectrik_admin';
        const c_id = AuthStore.getCustomer().c_id;
        let dids = this.state.devices.filter(d => d.checked).map(d => d.id);

        dids.forEach(did => {
            let singleDid = [];
            singleDid.push(did);
        })

        let token = AuthStore.getToken();
        let tokenParts = token.split('.');
        let tokenHash = tokenParts[tokenParts.length - 1];
        BatteryLogStore.createDownloadRequest(c_id, Number(this.state.startDate), Number(this.state.endDate), dids, download_with_admin_access, tokenHash)
            .then((res) => {
                if (res && res.length > 0) {
                    this.startPingingServer();
                    this.checkFileStatus();
                }
                this.setState({ downloading: false, });
            }).catch(err => {
                this.setState({ downloading: false });
            });;
    }

    startPingingServer() {
        console.log(`staring - pinging server `)
        if (!this.state.setIntervalToken) {
            let setIntervalToken = setInterval(this.checkFileStatus.bind(this), 10000);
            this.setState({ setIntervalToken })
        }
    }

    clearIntervalOnError() {
        console.log(`stopping - pinging server`)
        if (this.state.setIntervalToken) {
            console.log(` token interval cleared`)
            let { setIntervalToken } = this.state;
            clearInterval(setIntervalToken);
            this.setState({ setIntervalToken: null })
        }
    }

    checkFileStatus() {
        console.log(`Status ${new Date()}`);
        const c_id = AuthStore.getCustomer().c_id;
        let token = AuthStore.getToken();
        let tokenParts = token.split('.');
        let tokenHash = tokenParts[tokenParts.length - 1];
        //this checks the status on server and 
        let that = this;
        BatteryLogStore.checkFileStatus(c_id, tokenHash).then(res => {
            if (res && res.length > 0) {
                let inProgressFiles = res.filter(f => f.fileStatus === 'CREATION_IN_PROGRESS');
                if (inProgressFiles.length > 0) {
                    this.startPingingServer();
                }
                that.setState({ overallStatus: res })
            }
            if (res && res.length === 0) {
                that.clearIntervalOnError();
            }
        })
    }



    onDeviceSelectionChange(dev, e) {
        let devices = this.state.devices;
        let device = devices.find(d => d.id === dev.id);
        if (device) {
            device.checked = !device.checked;
            this.setState({ devices })
        }
    }

    renderDownloadButton() {
        if (this.state.downloading) {
            let loading = `img/progress-bar-preloader.svg`;
            return (
                <div>Preparing document... <img src={loading} /></div>
            );
        } else {
            return <button onClick={this.downloadHandler.bind(this)} >Download Logs</button>;
        }
    }

    renderOverallStatus() {
        var mq = window.matchMedia("(max-width: 767px)");
        let containerStyle;
        if (mq.matches) {  //screen is not larger than 767px
            containerStyle = {
                'width': '100%',
                'marginBottom': '15px',
                'overflowY': 'hidden',
                'overflowX': 'auto',
                'border': 0
            };
        } else { //screen is larger than 767px
            containerStyle = {
                'minHeight': '.01%',
                'border': 0
            };
        }
        if (this.state.overallStatus && this.state.overallStatus.length > 0) {
            return (
                <div>
                    <h2>Download Requests</h2>
                    <BootstrapTable
                        containerStyle={containerStyle}
                        data={this.state.overallStatus}
                        striped={true}
                        hover={true}>
                        <TableHeaderColumn dataField="r_id" isKey={true} hidden={true} dataSort={true}>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="dids" width="25%" dataSort={true}>Device Id</TableHeaderColumn>
                        <TableHeaderColumn dataField="st" width="25%" dataSort={true} dataFormat={this.renderSDate.bind(this)}>Start Time</TableHeaderColumn>
                        <TableHeaderColumn dataField="et" width="25%" dataSort={true} dataFormat={this.renderEDate.bind(this)}>End Time </TableHeaderColumn>
                        <TableHeaderColumn dataField="fileStatus" width="25%" dataSort={true} dataFormat={this.renderStatus.bind(this)}>Status</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            );
        }
        return null;
    }


    renderSDate(cell, record) {
        let { st } = record;
        return moment.tz(st, "Asia/Kolkata").format('DD-MM-YYYY')
    }

    renderEDate(cell, record) {
        let { et } = record;
        return moment.tz(et, "Asia/Kolkata").format('DD-MM-YYYY')
    }

    renderStatus(cell, record) {
        let { fileStatus, fileUrl } = record;
        if (fileStatus === 'CREATION_IN_PROGRESS') {
            let loading = `img/progress-bar-preloader.svg`;
            return (
                <div>Preparing document... <img src={loading} style={{ "height": "20px" }} /></div>
            );
        }
        if (fileStatus === 'SUCCESS') {
            return <button className="btn btn-primary fa fa-cloud-download" onClick={(e) => window.open(fileUrl)}></button>;
        }
    }


    render() {
        if (this.state.error) {
            return (
                <div>{this.state.error}</div>
            );
        }


        if (this.state.devices) {
            let devices = this.state.devices.map(d =>
                <div key={d.id}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" onChange={this.onDeviceSelectionChange.bind(this, d)} checked={d.checked} />
                            {d.id}
                        </label>
                    </div>
                </div>
            );

            return (
                <div>
                    <div>
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-12">
                                <h2>Download Options</h2>
                            </div>
                            <div className="ibox float-e-margins">
                                <div className="col-lg-6">
                                    <div className="ibox-content">
                                        <form method="get" className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-sm-4 control-label">Start Date</label>
                                                <div className="col-sm-8">
                                                    <DatePicker onChange={this.onStartDateChange.bind(this)} value={new Date(this.state.startDate)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-4 control-label">End Date</label>
                                                <div className="col-sm-8">
                                                    <DatePicker onChange={this.onEndDateChange.bind(this)} value={new Date(this.state.endDate)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-4 control-label">Devices</label>
                                                <div className="col-sm-8">
                                                    {devices}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-8 col-sm-offset-4">
                                                    {this.renderDownloadButton()}
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                {this.renderOverallStatus()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default DownloadLogs