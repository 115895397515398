import React from 'react';
import $ from 'jquery';

/**
 *  Parent class for all controls that need to update JsonObject based on jsonPath of the leaf node.
 */

export default class BoundControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    /**
     * UpdateJsonAppendToCollection is meant to be used to add new values to a collection
     * E.g
     * store: core_disclosure 
     * jsonPath: core_disclosure.medicinal_products (parent node of repeating element) jsonLeafNode: medicinal_product (repeating node)
     * value: 
     * @param {object} store 
     * @param {string} jsonPath 
     * @param {object} value 
     */
    UpdateJsonAppendToCollection(store, jsonPath, value) {
        //split the jsonPath by .
        var path = jsonPath.split('.');
        var objectToUpdate = store;
        var pathLength = $(path).length;
        var leaf = path[pathLength - 1];

        //navigate to this path
        $(path).each(function (ind, val) {
            if (ind < pathLength - 1) {
                if (val.indexOf('[') === -1) {
                    if (objectToUpdate[val]) {
                        objectToUpdate = objectToUpdate[val];
                    }
                    else {
                        if (process.env.NODE_ENV === 'production') {
                            objectToUpdate[val] = {};
                        }
                        else {
                            throw new Error("Check the json path (" + jsonPath + "). Element doesn't exist");
                        }
                    }
                }
                else {
                    let propertyPart = val.substring(0, val.indexOf('['));
                    let indexPart = val.substring(val.indexOf('[') + 1, val.indexOf(']'));
                    objectToUpdate = (objectToUpdate[propertyPart])[indexPart];
                }
            }
        });
        if (objectToUpdate[leaf]) {
            objectToUpdate[leaf].push(value);
        }
        else {
            objectToUpdate[leaf] = [];
            objectToUpdate[leaf].push(value);
        }
        this.applyIndexes(objectToUpdate[leaf]);
    }

    /**
     * Reapplys indexs to repeating elements when a new element gets added
     * @param {*} repeatingElement 
     */
    applyIndexes(repeatingElement) {
        for (let i = 0; i < repeatingElement.length; i++) {
            repeatingElement[i]['@index'] = i;
        }
    }

    /**
     * Removes an object from the store identified by jsonPath, key, value
     * E.g
     * store: window.cdf.core_disclosure
     * jsonPath: core_disclosure.medicinal_products.medicinal_product
     * key: code (this is assumed to uniquely identify this repeating node)
     * value: value of code 
     * @param {object} store 
     * @param {string} jsonPath 
     * @param {string} key 
     * @param {string} value 
     */
    UpdateJsonRemoveFromCollection(store, jsonPath, key, value) {
        //split the jsonPath by .
        var path = jsonPath.split('.');
        var objectToUpdate = store;
        var pathLength = $(path).length;
        var leaf = path[pathLength - 1];

        //navigate to this path
        $(path).each(function (ind, val) {
            if (ind < pathLength - 1) {
                if (val.indexOf('[') === -1) {
                    if (objectToUpdate[val]) {
                        objectToUpdate = objectToUpdate[val];
                    }
                    else {
                        if (process.env.NODE_ENV === 'production') {
                            objectToUpdate[val] = {};
                        }
                        else {
                            throw new Error("Check the json path (" + jsonPath + "). Element doesn't exist");
                        }
                    }
                }
                else {
                    let propertyPart = val.substring(0, val.indexOf('['));
                    let indexPart = val.substring(val.indexOf('[') + 1, val.indexOf(']'));
                    objectToUpdate = (objectToUpdate[propertyPart])[indexPart];
                }
            }
        });

        if (objectToUpdate[leaf]) {
            let itemToRemove = objectToUpdate[leaf].findIndex(i => i[key]['#text'] === value);
            if (itemToRemove !== -1) {
                objectToUpdate[leaf].splice(itemToRemove, 1);
                this.applyIndexes(objectToUpdate[leaf]);
            }
        }
    }


    /**
     * Removes an object from collection identified by index
     * @param {object} store 
     * @param {string} jsonPath 
     * @param {var} index 
     */
    UpdateJsonRemoveFromCollectionByIndex(store, jsonPath, index) {
        //split the jsonPath by .
        var path = jsonPath.split('.');
        var objectToUpdate = store;
        var pathLength = $(path).length;
        var leaf = path[pathLength - 1];

        //navigate to this path
        $(path).each(function (ind, val) {
            if (ind < pathLength - 1) {
                if (val.indexOf('[') === -1) {
                    if (objectToUpdate[val]) {
                        objectToUpdate = objectToUpdate[val];
                    }
                    else {
                        if (process.env.NODE_ENV === 'production') {
                            objectToUpdate[val] = {};
                        }
                        else {
                            throw new Error("Check the json path (" + jsonPath + "). Element doesn't exist");
                        }
                    }
                }
                else {
                    let propertyPart = val.substring(0, val.indexOf('['));
                    let indexPart = val.substring(val.indexOf('[') + 1, val.indexOf(']'));
                    objectToUpdate = (objectToUpdate[propertyPart])[indexPart];
                }
            }
        });

        if (objectToUpdate[leaf]) {
            let itemToRemove = objectToUpdate[leaf][index];
            if (itemToRemove) {
                objectToUpdate[leaf].splice(index, 1);
                this.applyIndexes(objectToUpdate[leaf]);
            }
        }
    }

    /**
     * UpdateJson takes the following three parameters i.e. store (object to update), jsonPath (the address of the leaf node to update), value )
     * This function doesn't expect an array in jsonPath
     * E.g
     * The following values of parameters
     * store: window.cdf.core_disclosure (the object that stores the entire form)
     * jsonPath: window.cdf.core_disclosure.brief_title.#text
     * value: abcd
     * 
     * will set the value of window.cdf.core_disclosure.brief_title[#text] = abcd
     * 
     * @param {object} store 
     * @param {string} jsonPath 
     * @param {object} value 
     */
    UpdateJson(store, jsonPath, value) {
        //split the jsonPath by .
        var path = jsonPath.split('.');
        var objectToUpdate = store;
        var pathLength = $(path).length;
        var leaf = path[pathLength - 1];

        //navigate to this path
        $(path).each(function (ind, val) {
            if (ind < pathLength - 1) {
                if (objectToUpdate[val]) {
                    objectToUpdate = objectToUpdate[val];
                }
                else {
                    if (process.env.NODE_ENV === 'production') {
                        objectToUpdate[val] = {};
                    }
                    else {
                        throw new Error("Check the json path (" + jsonPath + "). Element doesn't exist");
                    }
                }
            }
        });
        objectToUpdate[leaf] = value;
    }

    /**
     * getValue returns the leaf node value from the store whose path is identified by jsonPath
     * E.g
     * The following values of parameters
     * store: window.cdf.core_disclosure (the object that stores the entire form)
     * jsonPath: window.cdf.core_disclosure.brief_title.#text
     * 
     * will return the value of window.cdf.core_disclosure.brief_title[#text]
     * 
     * @param {object} store 
     * @param {string} jsonPath 
     * 
     */
    getValue(store, jsonPath) {
        try {
            //split the jsonPath by .
            var path = jsonPath.split('.');
            var objectToUpdate = store;
            var pathLength = $(path).length;
            var leaf = path[pathLength - 1];

            //navigate to this path
            $(path).each(function (ind, val) {
                if (ind < pathLength - 1) {
                    objectToUpdate = objectToUpdate[val];
                }
            });
            return objectToUpdate[leaf];
        }
        catch (e) {
            return null;
        }
    }

    getValue2(store, jsonPath) {
        try {
            //split the jsonPath by .
            var path = jsonPath.split('.');
            var objectToUpdate = store;
            var pathLength = $(path).length;
            var leaf = path[pathLength - 1];

            //navigate to this path
            $(path).each(function (ind, val) {
                if (val.indexOf('[') === -1) {
                    if (objectToUpdate[val]) {
                        objectToUpdate = objectToUpdate[val];
                    }
                }
                else {
                    let propertyPart = val.substring(0, val.indexOf('['));
                    let indexPart = val.substring(val.indexOf('[') + 1, val.indexOf(']'));
                    objectToUpdate = (objectToUpdate[propertyPart])[indexPart];
                }
            });
            if (leaf.indexOf['['] === -1) {
                return objectToUpdate[leaf];
            } else {
                return objectToUpdate;
            }
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}