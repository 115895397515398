import React, { Component } from 'react';
import AuthStore from '../stores/AuthStore';
import CustomerStore from '../stores/CustomerStore';
import Card from '../components/common/CustomerCard';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';

class CustomerDashboard extends Component {
    constructor() {
        super();
        this.stateToken = null;
        this.socketToken = null;
        this.state = {
            showDelete: false,
            customers: [],
            customer: null
        }
    }

    componentWillMount() {
        let user = AuthStore.getUser();
        CustomerStore.fetchAllCustomer(user).then(data => {
            this.setState({ customers: data });
        })
    }

    onCustomerClickHandler(c_id, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.history.push(`/customers/${c_id}`);
    }

    onCustomerDeleteClickHandler(c_id, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ showDelete: true, c_id: c_id });
        console.log(this.state.showDelete)
    }

    showDeleteRender() {
        if (this.state.showDelete) {
            const currentUser = AuthStore.getUser();
            let customer = this.state.customers.find(c => c.c_id === this.state.c_id);
            if (customer) {
                let customerName = customer.c_name;
                return <SweetAlert
                    danger
                    showCancel
                    confirmBtnText={"Yes, delete it !"}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={"Delete customer: " + customerName + " ?"}
                    onConfirm={this.deleteCustomer.bind(this)}
                    onCancel={this.cancelDelete.bind(this)}
                >
                    You will not be able to undo this action!
                    </SweetAlert>
            }
        }
    }

    deleteCustomer(e) {
        console.log('delete handler')
        CustomerStore.deleteCustomer(this.state.c_id, AuthStore.getUser()).then(data => {
            let user = AuthStore.getUser();
            CustomerStore.fetchAllCustomer(user).then(data => {
                this.setState({ customers: data, showDelete: false });
            })
        })
    }

    cancelDelete() {
        this.setState({ showDelete: false });
    }

    createDeviceLink() {
        return <Link to="/customers/create" className="btn btn-primary pull-right" ><i className="fa fa-plus-square"></i></Link>;
    }


    render() {
        const deviceLink = this.createDeviceLink();
        if (this.state.customers && this.state.customers.length > 0 ) {
            let customers = this.state.customers.map((customer) =>
                <Card
                    id={customer.c_id}
                    key={customer.c_id}
                    display={customer.c_name}
                    renderDelete={customer.c_id !== '001'}
                    onClickHandler={this.onCustomerClickHandler.bind(this, customer.c_id)}
                    onDeleteHandler={this.onCustomerDeleteClickHandler.bind(this, customer.c_id)}
                />
            );
            return (
                <div>
                    {this.showDeleteRender()}
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            <h2>Customers</h2>
                        </div>
                        <div className="col-lg-2">
                            <h2>{deviceLink}</h2>
                        </div>
                    </div>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="row">
                            {customers}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default CustomerDashboard