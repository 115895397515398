import React, { Component } from 'react';
import BoundInput from '../components/common/BoundInput';
import UserStore from '../stores/UserStore';
import AuthStore from '../stores/AuthStore';

export default class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            draftUser: { username: AuthStore.getUser().username, password: '', newPassword1: '', newPassword2: '' },
            mode: 'create',
            error: ''
        }
    }

    componentWillMount() {
        let loggedInUser = AuthStore.getUser();
        let draftUser = this.state.draftUser;
        draftUser.username = loggedInUser.username;
        this.setState({ draftUser });
    }

    onSaveHandler(e) {
        if (e) {
            e.preventDefault();
        }
        let hasErrors = false;
        if (this.state.draftUser.password === '' || this.state.draftUser.newPassword1 === '' || this.state.draftUser.newPassword2 === '') {
            hasErrors = true;
            this.setState({ error: "Please provide values for all fields" });
        }

        if (this.state.draftUser.newPassword1 !== this.state.draftUser.newPassword2) {
            hasErrors = true;
            this.setState({ error: "Passwords don't match" });
        }

        if (!hasErrors) {
            UserStore.changePassword(this.state.draftUser).then(data => {
                //this.props.history.push('/users');
            }).catch(err => {
                this.setState({ error: err.message });
            });
        }
    }

    onCancelHandler() {
        this.props.history.push('/users');
    }


    render() {
        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5><label className="text-danger">{this.state.error}</label></h5>
                        </div>
                        <div className="ibox-content">
                            <form method="get" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Current Password</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="password"
                                            store={this.state.draftUser}
                                            mode={this.state.mode === 'edit' ? 'readonly' : this.state.mode}
                                            placeholder="Current Password"
                                            jsonPath="password"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">New Password</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="password"
                                            store={this.state.draftUser}
                                            mode={this.state.mode}
                                            placeholder="New Password"
                                            jsonPath="newPassword1"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Re-Enter Password</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="password"
                                            store={this.state.draftUser}
                                            mode={this.state.mode}
                                            placeholder="Re Enter Password"
                                            jsonPath="newPassword2"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-8 col-sm-offset-4">
                                        <button className="btn btn-white" onClick={this.onCancelHandler.bind(this)}>Cancel</button>
                                        <button className="btn btn-primary" onClick={this.onSaveHandler.bind(this)}>Save changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
