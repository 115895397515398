import React, { Component } from 'react';
import AuthStore from '../stores/AuthStore';
import CustomerStore from '../stores/CustomerStore';
import Card from '../components/common/CustomerCard';
import pubsub from 'pubsub-js';


class ChangeCustomers extends Component {
    constructor() {
        super();
        this.state = {
            customers: []
        }
    }

    componentWillMount() {
        let user = AuthStore.getUser();
        CustomerStore.fetchAllCustomer(user).then(data => {
            this.setState({ customers: data });
        })
    }

    onCustomerClickHandler(c_id, e) {
        if (e) {
            e.preventDefault();
        }
        let customer = this.state.customers.find(c => c.c_id === c_id);
        if (customer) {
            AuthStore.setCustomer(customer);
            pubsub.publishSync('CUSTOMER_CHANGED', customer);
            this.props.history.push('/dashboard');
        }
    }


    render() {

        if (this.state.customers) {
            let customers = this.state.customers.map((customer) =>
                <Card
                    id={customer.c_id}
                    key={customer.c_id}
                    display={customer.c_name}
                    onClickHandler={this.onCustomerClickHandler.bind(this, customer.c_id)}

                />
            );
            return (
                <div>
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            <h2>Change Customer Context</h2>
                            <label>Selecting a customer here will change the dashboard and download view for the selected customer</label>
                        </div>
                    </div>
                    <div className="wrapper wrapper-content animated fadeInRight">
                        <div className="row">
                            {customers}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default ChangeCustomers