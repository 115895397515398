import React from 'react';
import BoundControl from './BoundControl';

export default class BoundCheckboxListView extends BoundControl {
    onChange(internalValue, e) {
        let val = e.target.checked;
        //if val is true add the item to collection
        if (val === true) {
            this.props.store[this.props.jsonPath].push(internalValue);
        } else {
            let itemIndex = this.props.store[this.props.jsonPath].indexOf(internalValue);
            this.props.store[this.props.jsonPath].splice(itemIndex, 1);
        }
        super.setState({ value: val });
    }

    render() {
        let checkBoxCollection = this.props.list;
        let val = super.getValue(this.props.store, this.props.jsonPath);
        if (checkBoxCollection && val) {
            let checkboxList = checkBoxCollection.map(item =>
                <div key={item.internalValue}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" onChange={this.onChange.bind(this, item.internalValue)} checked={val.indexOf(item.internalValue) !== -1} />
                            {item.displayValue}
                        </label>
                    </div>
                </div>
            )
            let readOnlyList = checkBoxCollection.map(item =>
                <div key={item.internalValue}>
                    <label>{item.displayValue} ,</label>
                </div>
            )

            if (this.props.mode === "edit" || this.props.mode === "create") {
                return (
                    <div>
                        {checkboxList}
                    </div>
                );
            }

            return (
                <div>
                    {readOnlyList}
                </div>
            );
        }
        return null;
    }
}

