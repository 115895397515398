import axios from 'axios';
import Config from '../config';

class BatteryLogStore {
    checkFileStatus(c_id, tokenHash) {
        let queryString = `/batterylogs/cust/${c_id}/${tokenHash}`;
        let url = Config.REACT_APP_API_ROOT_URL + queryString;

        return axios({
            method: 'get',
            url: url,
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err);
        });
    }
    /**
     * fetch device data
     */
    createDownloadRequest(c_id, st, et, dids, download_with_admin_access, tokenHash) {
        let queryString = `/batterylogs/cust/${c_id}`;
        let data = { "tokenHash": tokenHash };
        if (st) {
            data["st"] = st;
        }
        if (et) {
            data["et"] = et;
        }
        if (dids) {
            data["dids"] = dids;
        }
        if (download_with_admin_access) {
            data["download_with_admin_access"] = download_with_admin_access;
        }
        let url = Config.REACT_APP_API_ROOT_URL + queryString;

        console.log(JSON.stringify(data));

        return axios({
            method: 'post',
            data: data,
            url: url
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err);
        });
    }

    fetchLatestLogs(c_id, user) {
        let queryString = `/batterylogs/latest/${c_id}`;
        let url = Config.REACT_APP_API_ROOT_URL + queryString;
        return axios({
            method: 'post',
            url: url,
            data: user
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err);
        });
    }
}

let batteryLogStore = window.BatteryLogStore = new BatteryLogStore();
export default batteryLogStore;