import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import Config from './config';
//Layouts
import BlankLayout from './components/layouts/BlankLayout';
import MainLayout from './components/layouts/MainLayout';

//Views
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import DownloadLogs from './views/DownloadLogs';
import ChangePassword from './views/ChangePassword';

//customers
import CustomerDashboard from './views/CustomerDashboard';
import ChangeCustomers from './views/ChangeCustomers';
import CustomerBatteryLogs from './views/CustomerBatteryLogs';
import SaveCustomer from './views/SaveCustomer';

//users
import UserDashboard from './views/UserDashboard';
import SaveUser from './views/SaveUser';

//stores
import AuthStore from './stores/AuthStore';

const MainRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    AuthStore.getToken() ? (
      <MainLayout>
        <Component {...props} />
      </MainLayout>
    ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
  )} />
);

const BlankRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <BlankLayout >
      <Component {...props} />
    </BlankLayout>
  )} />
);

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={'/' + Config.REACT_APP_TITLE}>
        <Switch>
          <MainRoute exact={true} path="/" component={Dashboard} />
          <MainRoute exact={true} path="/changePassword" component={ChangePassword} />
          <BlankRoute exact={true} path="/login" component={Login} />
          <MainRoute exact={true} path="/dashboard" component={CustomerBatteryLogs} />
          <MainRoute exact={true} path="/download" component={DownloadLogs} />
          

          <MainRoute exact={true} path="/changeCustomer" component={ChangeCustomers} />
          <MainRoute exact={true} path="/customers" component={CustomerDashboard} />
          <MainRoute exact={true} path="/customers/:c_id" component={SaveCustomer} />
          
          <MainRoute exact={true} path="/users" component={UserDashboard} />
          <MainRoute exact={true} path="/users/:username" component={SaveUser}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
