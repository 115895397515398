import React, { Component } from 'react';
import BoundInput from '../components/common/BoundInput';
import CustomerStore from '../stores/CustomerStore';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SweetAlert from 'react-bootstrap-sweetalert';
class SaveCustomer extends Component {
    constructor() {
        super();
        this.state = {
            draftCustomer: { c_id: '', c_name: '' },
            draftDevice: {},
            deviceMode: 'create',
            mode: 'create',
            error: '',
            showDelete: false
        }
    }

    componentWillMount() {
        const c_id = this.props.match.params.c_id;
        if (c_id !== 'create') {
            CustomerStore.fetchCustomer(c_id)
                .then((customer) => {
                    this.setState({ draftCustomer: customer });
                }).catch((err) => {
                    this.setState({ error: err.message });
                });
        }
    }

    onSaveHandler(e) {
        if (e) {
            e.preventDefault();
        }
        CustomerStore.saveCustomer(this.state.draftCustomer, 'add').then(data => {
            this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
        }).catch(err => {
            this.setState({ error: err.message });
        });
    }

    onCancelHandler() {
        this.props.history.push('/customers');
    }

    onSaveDeviceHandler(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('saving device info');
        CustomerStore.saveCustomer(this.state.draftCustomer, 'update');
        this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
    }

    onCancelDeviceHandler(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ deviceMode: 'create', draftDevice: {}, error: null });
    }

    onAddDeviceHandler(e) {
        if (e) {
            e.preventDefault();
        }
        let customer = this.state.draftCustomer;
        if (this.state.draftDevice.id && this.state.draftDevice.id.length === 12) {
            if (customer.devices) {
                var existingDevice = customer.devices.find(d => d.id === this.state.draftDevice.id);
                if (!existingDevice) {
                    customer.devices.push(this.state.draftDevice);
                    CustomerStore.saveCustomer(customer);
                    this.setState({ draftCustomer: customer, deviceMode: 'create', draftDevice: {}, error: null });
                } else {
                    this.setState({ error: 'Device already exists' });
                }
            } else {
                customer.devices = [];
                customer.devices.push(this.state.draftDevice);
                CustomerStore.saveCustomer(customer);
                this.setState({ draftCustomer: customer, deviceMode: 'create', draftDevice: {}, error: null });
            }
        } else {
            this.setState({ error: 'Invalid Device ID format' })
        }
    }
    renderError() {
        if (this.state.error) {
            return (
                <div className="error">{this.state.error}</div>
            );
        }
    }

    onDeleteHandler(device, e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ showDelete: true, draftDevice: device });
    }

    onEditHandler(device, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ draftDevice: device, deviceMode: 'edit' });
    }

    renderEdit(cell, record) {
        return <button className="btn btn-info fa fa-edit" onClick={this.onEditHandler.bind(this, record)}></button>;
    }

    renderDelete(cell, record) {
        return <button className="btn btn-danger fa fa-trash" onClick={this.onDeleteHandler.bind(this, record)}></button>;
    }


    showDeleteRender() {
        if (this.state.showDelete) {
            let device = this.state.draftDevice;
            return <SweetAlert
                danger
                showCancel
                confirmBtnText={"Yes, delete it !"}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={"Delete device: " + device.id + " ?"}
                onConfirm={this.deleteDeviceHandler.bind(this)}
                onCancel={this.cancelDeleteDeviceHandler.bind(this)}
            >
                You will not be able to undo this action!
        </SweetAlert>
        }
    }

    deleteDeviceHandler(e) {
        console.log('delete handler')
        let customer = this.state.draftCustomer;
        let deviceIndex = customer.devices.findIndex(d => d.id === this.state.draftDevice.id);

        if (deviceIndex !== -1) {
            customer.devices.splice(deviceIndex, 1);
            CustomerStore.saveCustomer(customer).then(res => {
                this.setState({ draftCustomer: customer, draftDevice: {}, showDelete: false, deviceMode: 'create' })
            })
        }
        this.setState({ showDelete: false });
    }


    cancelDeleteDeviceHandler() {
        this.setState({ showDelete: false });
    }

    render() {
        var mq = window.matchMedia("(max-width: 767px)");
        let containerStyle;
        if (mq.matches) {  //screen is not larger than 767px
            containerStyle = {
                'width': '100%',
                'marginBottom': '15px',
                'overflowY': 'hidden',
                'overflowX': 'auto',
                'border': 0
            };
        } else { //screen is larger than 767px
            containerStyle = {
                'minHeight': '.01%',
                'border': 0
            };
        }

        return (
            <div className="row">
                {this.showDeleteRender()}


                <div className="col-lg-6">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Customer </h5>
                            <div>
                                <label className="error"> {this.renderError()}</label>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <form method="get" className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Id</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="text"
                                            store={this.state.draftCustomer}
                                            mode={'edit'}
                                            placeholder="Id"
                                            jsonPath="c_id"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Name</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="text"
                                            store={this.state.draftCustomer}
                                            mode={'edit'}
                                            placeholder="Name"
                                            jsonPath="c_name"
                                        />
                                    </div>

                                </div>
                                <div className="form-group">
                                    <div className="col-sm-8 col-sm-offset-4">
                                        <button className="btn btn-white" onClick={this.onCancelHandler.bind(this)}>Cancel</button>
                                        <button className="btn btn-primary" onClick={this.onSaveHandler.bind(this)}>Save changes</button>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Devices</h5>
                        </div>
                        <div className="ibox-content">
                            <form method="get" className="form-horizontal">

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Device Id</label>
                                    <div className="col-sm-8">
                                        <BoundInput
                                            type="text"
                                            store={this.state.draftDevice}
                                            mode={'edit'}
                                            placeholder="Device Id"
                                            jsonPath="id"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-8 col-sm-offset-4">
                                            <button className="btn btn-white" onClick={this.onCancelDeviceHandler.bind(this)}>Cancel</button>
                                            {this.state.deviceMode === 'create' ?
                                                <button className="btn btn-primary" onClick={this.onAddDeviceHandler.bind(this)}>Add New</button>
                                                : <button className="btn btn-primary" onClick={this.onSaveDeviceHandler.bind(this)}>Update Device</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="col-sm-12">

                                        <BootstrapTable
                                            containerStyle={containerStyle}
                                            data={this.state.draftCustomer.devices}
                                            striped={true}
                                            hover={true}>
                                            <TableHeaderColumn dataField="id" width="90%" isKey={true} dataSort={true}>ID</TableHeaderColumn>
                                            <TableHeaderColumn width="20" dataAlign="right" dataFormat={this.renderEdit.bind(this)} editable={false}>Edit</TableHeaderColumn>
                                            <TableHeaderColumn width="20" dataAlign="right" dataFormat={this.renderDelete.bind(this)} editable={false}>Delete</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SaveCustomer