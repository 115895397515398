import React from 'react';

class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            version: {
                major: 1,
                minor: 0,
                patch: 0
            }
        }
    }

    componentWillMount() {
    }

    render() {
        if (this.state.version) {
            return <div className="footer">
                <div>
                    Delectrik ({this.state.version.major + "." + this.state.version.minor + "." + this.state.version.patch})
                </div>
            </div>
        } else {
            return (
                <div className="footer">
                    <div>

                    </div>
                </div>
            )
        }
    }
}

export default Footer