import React from 'react';
import BoundControl from './BoundControl';

export default class BoundSelect extends BoundControl {
    constructor() {
        super();
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        this.setState({ loaded: true });
    }

    onChange(e) {
        if (this.state.loaded) {
            let val = e.target.value;
            if (this.props.jsonPath) {
                super.UpdateJson(this.props.store, this.props.jsonPath, val);
                super.setState({ value: val });
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            }
        }
    }

    render() {
        let val = this.getValue(this.props.store, this.props.jsonPath);
        if (!val) {
            val = '';
        }
        let dValue = '';

        if (val) {
            if (this.props.options) {
                let option = this.props.options.find(o => o.internalValue === val);
                if (option) {
                    dValue = this.props.options.find(o => o.internalValue === val)["displayValue"];
                }
            }
        }
        let fId = '';
        if (this.props.fId) {
            fId = super.getValue(this.props.store, this.props.fId);
        }
        //let errorMessages = this.parseValidations(this.props.validations, fId);

        let options = this.props.options.map(o =>
            <option key={o.internalValue} value={o.internalValue} > {o.displayValue}</option>
        )
        if (this.props.mode === "edit" || this.props.mode === "create") {
            return (
                <div>
                    <select className="form-control" onChange={this.onChange.bind(this)} value={val}>
                        {options}
                    </select>
                    {/* {errorMessages} */}
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="form-control-static">{dValue}</div>
                    {/* {errorMessages} */}
                </div>
            );
        }
    }
}