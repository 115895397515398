import React from 'react';
import BoundControl from './BoundControl';

export default class BoundInput extends BoundControl {

    onChange(e) {
        let val = e.target.value;
        if (this.props.jsonPath) {
            super.UpdateJson(this.props.store, this.props.jsonPath, val);
            super.setState({ value: val });
        }
    }
    render() {
        let val = super.getValue(this.props.store, this.props.jsonPath);
        let validationMessages = null;
        let fId = '';
        val = val ? val : "";
        let charCount =  val.length;
        let maxCharMessage = charCount < this.props.maxChar ? <div>{charCount} of {this.props.maxChar}</div> : <div className="text text-danger">{charCount} of {this.props.maxChar}</div>;

        if (this.props.mode === "edit" || this.props.mode === "create") {
            return (
                <div>
                    <input type={this.props.type} className="form-control" placeholder={this.props.placeholder} onChange={this.onChange.bind(this)} value={val} />
                    <p className="help-block">{this.props.help}</p>
                </div>);
        }
        else {
            return (
                <div>
                    <div className="form-control-static">{val}</div>
                    {validationMessages}
                </div>
            );
        }
    }
}

