import axios from 'axios';
import Config from '../config';


class UserStore {
    /**
     * fetch users by customer by id
     */
    fetchUsers(c_id, type) {
        return axios({
            method: 'post',
            url: Config.REACT_APP_API_ROOT_URL + `/users/${c_id}`,
            data: { type: type }
        }).then((res) => {
            return res.data;
        });
    }


    fetchUserById(c_id, username) {
        return axios({
            method: 'get',
            url: Config.REACT_APP_API_ROOT_URL + `/users/${c_id}/${username}`
        }).then((res) => {
            return res.data;
        });
    }

    userTypes(c_id, type) {
        return axios({
            method: 'post',
            url: Config.REACT_APP_API_ROOT_URL + `/users/${c_id}/userTypes`,
            data: { type }
        }).then((res) => {
            return res.data;
        });
    }

    deleteUser(c_id, user) {
        return axios({
            method: 'post',
            url: Config.REACT_APP_API_ROOT_URL + `/users/${c_id}/delete`,
            data: user
        }).then((res) => {
            return res.data;
        });
    }


    updateUser(c_id, user, mode) {
        console.log('saving user ...')
        let queryString = mode === 'edit' ? `/users/${c_id}/update` : `/users/${c_id}/add`;
        let url = Config.REACT_APP_API_ROOT_URL + queryString;
        let method = 'post';
        return axios({
            method: method,
            data: user,
            url: url
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err);
        });
    }

    changePassword(user) {
        let queryString = `/users/changePassword`;
        let url = Config.REACT_APP_API_ROOT_URL + queryString;
        let method = 'post';
        return axios({
            method: method,
            data: user,
            url: url
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err);
        });
    }
}

let userStore = window.UserStore = new UserStore();
export default userStore;