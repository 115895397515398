import React, { Component } from 'react';
import AuthStore from '../stores/AuthStore';
import BatteryLogStore from '../stores/BatteryLogStore';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment-timezone';

class CustomerBatteryLogs extends Component {
    constructor() {
        super();
        this.state = {
            logs: [],
            startDate: new Date(),
            endDate: new Date(),
            devices: [],
            error: null
        }
    }

    componentWillMount() {

        let start = this.state.startDate;
        start.setHours(0, 0, 0);
        let end = this.state.endDate;
        end.setHours(23, 59, 59);
        this.setState({ startDate: start, endDate: end });
        this.fetchLogs();
    }

    fetchLogs() {
        const c_id = AuthStore.getCustomer().c_id;
        const user = AuthStore.getUser();
        BatteryLogStore.fetchLatestLogs(c_id, user)
            .then((res) => {
                if (res) {
                    if (res.data) {
                        let items = this.formatContent(res.data);
                        let customer = AuthStore.getCustomer();
                        let devices = customer.devices.map(d => Object.assign(d, { checked: true }))
                        this.setState({ logs: items, error: null, devices: devices });
                    } else {
                        this.setState({ error: res.Error });
                    }
                }
            });
    }

    onStartDateChange(sd) {
        console.log(sd);
        if (sd) {
            sd.setHours(0, 0, 0, 0);
            this.setState({ startDate: sd });
        }
    }

    onEndDateChange(ed) {
        console.log(ed);
        if (ed) {
            ed.setHours(23, 59, 59);
            this.setState({ endDate: ed });
        }
    }

    downloadHandler(e) {
        if (e) {
            e.preventDefault();
        }
        const c_id = AuthStore.getCustomer().c_id;
        let dids = this.state.devices.filter(d => d.checked).map(d => d.id);
        BatteryLogStore.createDownloadRequest(c_id, Number(this.state.startDate), Number(this.state.endDate), dids)
            .then((res) => {
                if (res) {
                    console.log('download request successfully created');
                    window.open(res);
                }
            });

    }

    formatContent(serverItems) {
        //if (AuthStore.getUser().type === 'delectrik_admin') {
            let items = serverItems.map(i => Object.assign({
                r_id: i.r_id,
                site_id: i.siteId,
                state: i.STATE,
                site_name: i.siteName,
                dc_voltage: i.DB_DC_Voltage_mV,
                dc_current: i.DB_DC_Current_mA,
                soc_percent: i.SOC_SOC_PERCENT,
                power: i.CAP_POWER_W,
                energy: i.CAP_Energy_WH,

                temperature: i.DT_Positive_Tank_Temp_C,
                esr: i.SOC_ESR_Charge,
                leftPumpCurrent: i.PMPI_Negative_Pump_Current_mA,
                rightPumpCurrent: i.PMPI_Positive_Pump_Current_mA,

                error_code3: i.NERR_E_Code3,

                time: moment.tz(i.sts, "Asia/Kolkata").format('DD-MM-YYYY HH:mm:ss')
            }));
            return items;
        // } else {
        //     let items = serverItems.map(i => Object.assign({
        //         r_id: i.r_id,
        //         site_id: i.siteId,
        //         state: i.STATE,
        //         site_name: i.siteName,
        //         dc_voltage: i.DB_DC_Voltage_mV,
        //         dc_current: i.DB_DC_Current_mA,
        //         soc_percent: i.SOC_SOC_PERCENT,
        //         power: i.CAP_POWER_W,
        //         energy: i.CAP_Energy_WH,
        //         error_code3: i.NERR_E_Code3,
        //         time: moment.tz(i.sts, "Asia/Kolkata").format('DD-MM-YYYY HH:mm:ss')
        //     }));
        //     return items;
        // }
    }

    renderTable() {
        var mq = window.matchMedia("(max-width: 767px)");
        let containerStyle;
        if (mq.matches) {  //screen is not larger than 767px
            containerStyle = {
                'width': '100%',
                'marginBottom': '15px',
                'overflowY': 'hidden',
                'overflowX': 'auto',
                'border': 0
            };
        } else { //screen is larger than 767px
            containerStyle = {
                'minHeight': '.01%',
                'border': 0
            };
        }
        //if (AuthStore.getUser().type === 'delectrik_admin') {
            return <BootstrapTable
                containerStyle={containerStyle}
                data={this.state.logs}
                striped={true}
                hover={true}>
                <TableHeaderColumn dataField="r_id" isKey={true} hidden={true} dataSort={true}>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="site_id" width="80" dataSort={true}>Site Id </TableHeaderColumn>
                <TableHeaderColumn dataField="site_name" width="90" dataSort={true}>Site Name </TableHeaderColumn>
                <TableHeaderColumn dataField="state" width="80" dataSort={true}>State </TableHeaderColumn>
                <TableHeaderColumn dataField="dc_voltage" width="100" dataSort={true}>DC Voltage (mV) </TableHeaderColumn>
                <TableHeaderColumn dataField="dc_current" width="100" dataSort={true}>DC Current (mA)</TableHeaderColumn>
                <TableHeaderColumn dataField="soc_percent" width="50" dataSort={true}>SOC(%)</TableHeaderColumn>
                <TableHeaderColumn dataField="power" width="80" dataSort={true}>Power (W)</TableHeaderColumn>
                <TableHeaderColumn dataField="energy" width="80" dataSort={true}>Energy (WH) </TableHeaderColumn>

                <TableHeaderColumn dataField="temperature" width="80" dataSort={true}>Temperature (C)</TableHeaderColumn>
                <TableHeaderColumn dataField="esr" width="80" dataSort={true}>ESR</TableHeaderColumn>
                <TableHeaderColumn dataField="leftPumpCurrent" width="100" dataSort={true}>Left Pump Current (mA)</TableHeaderColumn>
                <TableHeaderColumn dataField="rightPumpCurrent" width="100" dataSort={true}>Right Pump Current (mA)</TableHeaderColumn>

                <TableHeaderColumn dataField="error_code3" width="100" dataSort={true}>Error Code 3 </TableHeaderColumn>
                <TableHeaderColumn dataField="time" width="180" dataSort={true}>Time </TableHeaderColumn>
            </BootstrapTable>
        // }
        // else {
        //     return <BootstrapTable
        //         containerStyle={containerStyle}
        //         data={this.state.logs}
        //         striped={true}
        //         hover={true}>
        //         <TableHeaderColumn dataField="r_id" isKey={true} hidden={true} dataSort={true}>ID</TableHeaderColumn>
        //         <TableHeaderColumn dataField="site_id" width="90" dataSort={true}>Site Id </TableHeaderColumn>
        //         <TableHeaderColumn dataField="site_name" width="20" dataSort={true}>Site Name </TableHeaderColumn>
        //         <TableHeaderColumn dataField="state" width="80" dataSort={true}>State </TableHeaderColumn>
        //         <TableHeaderColumn dataField="dc_voltage" width="100" dataSort={true}>DC Voltage (mV) </TableHeaderColumn>
        //         <TableHeaderColumn dataField="dc_current" width="100" dataSort={true}>DC Current (mA)</TableHeaderColumn>
        //         <TableHeaderColumn dataField="soc_percent" width="100" dataSort={true}>SOC(%)</TableHeaderColumn>
        //         <TableHeaderColumn dataField="power" width="100" dataSort={true}>Power (W)</TableHeaderColumn>
        //         <TableHeaderColumn dataField="energy" width="100" dataSort={true}>Energy (WH) </TableHeaderColumn>
        //         <TableHeaderColumn dataField="error_code3" width="100" dataSort={true}>Error Code 3 </TableHeaderColumn>
        //         <TableHeaderColumn dataField="time" width="180" dataSort={true}>Time </TableHeaderColumn>
        //     </BootstrapTable>
        // }
    }

    onDeviceSelectionChange(dev, e) {
        let devices = this.state.devices;
        let device = devices.find(d => d.id === dev.id);
        if (device) {
            device.checked = !device.checked;
            this.setState({ devices })
        }
    }


    renderCustomer() {
        let current_customer = AuthStore.getCustomer();
        return current_customer.c_name;
    }


    render() {
        if (this.state.error) {
            return (
                <div>{this.state.error}</div>
            );
        }

        if (this.state.logs) {
            return (
                <div>
                    <div>
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-8">
                                <h2>{this.renderCustomer()} : Latest Battery Logs</h2>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    {this.renderTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default CustomerBatteryLogs