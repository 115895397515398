import React from 'react';
import NavigationMenuItem from './NavigationMenuItem';
import AuthStore from '../../stores/AuthStore';

class Navigation extends React.Component {
    activeRoute(routeName) {
        return this.props.currentLocation.indexOf(routeName) > -1 ? "active" : "";
    }

    secondLevelActive(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    }

    render() {
        const user = AuthStore.getUser();
        const profilePic = user.profilePic == null || user.profilePic === '' ? "/img/profiles/profile.jpg" : "/img/profiles/" + user.profilePic;
        const role = user.type === 'delectrik_admin' ? 'Admin' : 'User';
        return (
            <nav className="navbar-default navbar-static-side" >
                <ul className="nav metismenu" id="side-menu" ref="menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            <span>
                                <img alt="profile picture" className="img-circle" src={profilePic} style={{ width: 48 }} />
                            </span>
                            <a data-toggle="dropdown" className="dropdown-toggle" href="/">
                                <span className="clear"> <span className="block m-t-xs"> <strong className="font-bold">{user.full_name}</strong>
                                </span>
                                    <span>Role ({role})</span>
                                </span>
                            </a>
                        </div>
                        <div className="logo-element">DK+</div>
                    </li>
                    <NavigationMenuItem
                        linkTo="/changeCustomer"
                        activeRoute={this.activeRoute.bind(this, "/changeCustomer")}
                        title="Change Customer"
                        className="fa fa-exchange"
                        permission="UI_CUSTOMERS"
                        location={this.props.location} />
                    <NavigationMenuItem
                        linkTo="/customers"
                        activeRoute={this.activeRoute.bind(this, "/customers")}
                        title="Customers"
                        className="fa fa-user-circle"
                        permission="UI_CUSTOMERS"
                        location={this.props.location} />
                    <NavigationMenuItem
                        linkTo="/users"
                        activeRoute={this.activeRoute.bind(this, "/users")}
                        title="Users"
                        className="fa fa-user-circle"
                        permission="UI_USERS"
                        location={this.props.location} />

                    <NavigationMenuItem
                        linkTo="/dashboard"
                        activeRoute={this.activeRoute.bind(this, "/dashboard")}
                        title="Dashboard"
                        className="fa fa-th-large"
                        permission="UI_DASHBOARD"
                        location={this.props.location} />

                    <NavigationMenuItem
                        linkTo="/download"
                        activeRoute={this.activeRoute.bind(this, "/download")}
                        title="Download"
                        className="fa fa-cloud-download"
                        permission="UI_DOWNLOAD"
                        location={this.props.location} />

                </ul>
            </nav>
        )
    }
}

export default Navigation