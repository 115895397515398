import React from 'react';
import AuthStore from '../stores/AuthStore';
import pubsub from 'pubsub-js';


class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null
        }
    }

    componentDidMount() {
        pubsub.publishSync('LOGOUT_EVENT', null);
        this.username.focus();
        AuthStore.logout();
    }

    componentWillUnmount() {
        this.setState({ error: null });
    }

    onClickHandler(e) {
        if (e) {
            e.preventDefault();
        }
        this.login();
    }

    login() {
        let me = this;
        this.disableLogin(true);
        AuthStore.login(this.username.value, this.password.value)
            .then((res) => {
                if (AuthStore.isLoggedIn()) {
                    pubsub.publishSync('LOGIN_EVENT', null);
                    let user = AuthStore.getUser();
                    if (user.type === 'delectrik_admin') {
                        me.props.history.push('/customers');
                    } else {
                        me.props.history.push('/dashboard');
                    }
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.status && err.response.status === 401) {
                    me.setState({ error: err.response.data.message });
                }
                if (err && err.message) {
                    me.setState({ error: err.message });
                }
                else {
                    me.setState({ error: 'Opps, the server is down...' });
                }
                this.disableLogin(false);
            });
    }

    disableLogin(buttonState) {
        if (this.LoginButton) {
            this.LoginButton.disabled = buttonState;
        }
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.login();
        }
    }

    getErrorMessage() {
        let errorStyle = {
            color: 'red'
        }
        return this.state.error != null ? <div style={errorStyle}>{this.state.error}</div> : null;
    }

    render() {
        let errorMessaage = this.getErrorMessage();
        return (
            <div className="middle-box text-center loginscreen animated fadeInDown">
                <div className="middle-box">
                    <div>
                        <h1 className="logo-name">DE</h1>
                    </div>
                    <h3>Welcome to Delectrik</h3>
                    <p>Remote Customer Access</p>
                    <form className="m-t" action="index.html">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Username" required="" ref={(input) => { this.username = input }} onKeyDown={this.onKeyDown.bind(this)} defaultValue='' />
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Password" required="" ref={(input) => { this.password = input }} onKeyDown={this.onKeyDown.bind(this)} defaultValue='' />
                        </div>
                        <button type="button" className="btn btn-primary block full-width m-b" onClick={this.onClickHandler.bind(this)}>Login</button>
                    </form>
                    {errorMessaage}
                    <p className="m-t"> <small>Delectrik &copy; 2019</small> </p>
                </div>
            </div>
        );
    }
}

export default Login;