import React from 'react';
import { Link } from 'react-router-dom';
import AuthStore from '../../stores/AuthStore';

export default class NavigationMenuItem extends React.Component {

    activeRoute(routeName) {
        console.log("activeRoute: ", this.props.activeRoute, " routeName: ", routeName)
        return this.props.activeRoute.indexOf(routeName) > -1 ? "active" : "";
    }

    render() {
        let activeRoute = this.props.activeRoute;
        let permission = this.props.permission;
        let linkTo = this.props.linkTo;
        let title = this.props.title;
        let hasAccess = false;
        let className = this.props.className;

        if (permission != null) {
            //this element requires permission
            let user = AuthStore.getUser();
            if (user && user.ui_permissions) {

                let userRoleExists = user.ui_permissions.find(r => r === permission);
                if (userRoleExists) {
                    hasAccess = true;
                }
            }
            else {
                hasAccess = false;
            }
            if (hasAccess) {
                return (
                    <li className={this.props.activeRoute({ activeRoute })}>
                        <Link to={linkTo}><i className={className}></i> <span className="nav-label">{title}</span></Link>
                    </li>
                );
            } else {
                return null;
            }
        } else {
            return (
                <li className={this.props.activeRoute({ activeRoute })}>
                    <Link to={linkTo}><i className={className}></i> <span className="nav-label">{title}</span></Link>
                </li>
            );
        }
    }
}