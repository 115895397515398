// //local dev
// const Config = {
//     REACT_APP_STATIC_RESOURCE_ROOT_URL: 'http://localost:3000',
//     REACT_APP_API_ROOT_URL: 'https://enfjo0vun8.execute-api.ap-south-1.amazonaws.com/dev',
//     REACT_APP_SOCKET_URL: '',
//     REACT_APP_TITLE: ''
// }

//prod deploy
const Config = {
    REACT_APP_STATIC_RESOURCE_ROOT_URL: 'https://d2c5uncyaucn34.cloudfront.net',
    REACT_APP_API_ROOT_URL: 'https://enfjo0vun8.execute-api.ap-south-1.amazonaws.com/dev',
    REACT_APP_SOCKET_URL: '',
    REACT_APP_TITLE: ''
}

export default Config;