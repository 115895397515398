import React from 'react'

export default class UserCard extends React.Component {
    onClickHandler(e) {
        if (this.props.onClickHandler) {
            this.props.onClickHandler(e);
        }
    }
    onDeleteHandler(e) {
        if (this.props.onDeleteHandler) {
            this.props.onDeleteHandler(e);
        }
    }

    renderDelete() {
        if (this.props.onDeleteHandler) {
            return <button onClick={this.onDeleteHandler.bind(this)} className="btn btn-danger pull-right" ><i className="fa fa-minus-square"></i></button>;
        }
    }

    render() {
        const display = this.props.display;
        const image = "/img/profiles/" + this.props.profilePic;
        return (
            <div className="col-lg-4">
                <div className="contact-box">
                    {this.renderDelete()}
                    <a onClick={this.onClickHandler.bind(this)} href="/">
                        <div className="col-sm-4">
                            <div className="text-center">
                                <img className="img-circle m-t-xs img-responsive" src={image} alt="device" style={{ width: 100 }} />
                                <div className="m-t-xs font-bold">{display}</div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <h3><strong>{display}</strong></h3>
                            <p>{this.props.id} </p>
                        </div>
                        <div className="clearfix"></div>
                    </a>
                </div>
            </div>
        );
    }
}