import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import AuthStore from '../src/stores/AuthStore';

//Styles
import './styles/bootstrap/dist/css/bootstrap.min.css';

import './styles/font-awesome/css/font-awesome.css';
import './styles/animate.css/animate.min.css';

import './styles/public/styles/style.css';


import './styles/inspinia/styles/style.css';
import './styles/custom_styles/custom.css';

// Add a request interceptor 
axios.interceptors.request.use(
    function (config) {
        if (AuthStore.getToken()) {
            config.headers['x-access-token'] = AuthStore.getToken();
        }
        // Do something before request is sent
        //$('#loading_spinner').show();
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Add a response interceptor 
axios.interceptors.response.use(
    function (response) {
        if (response.status === 401){
            AuthStore.logout();
        }
        // Do something with response data
        //$('#loading_spinner').hide();
        return response;
    },
    function (error) {
        // Do something with response error
        // if(error.response.status === 401){
        //     AuthStore.logout();
        //     window.location = Config.REACT_APP_API_ROOT_URL;
        // }
        return Promise.reject(error); 
    });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
