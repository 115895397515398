import axios from 'axios';
import Config from '../config';
let instance = null;

class AuthStore {
    constructor() {
        if (!instance) {
            instance = this;
        }

        const token = instance.getToken();
        const user = instance.getUser();

        if (token && user) {
            instance.user = user;
        }

        return instance;
    }

    isLoggedIn() {
        return !!this.getUser();
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    getUser() {
        if (sessionStorage.getItem('user')) {
            return JSON.parse(sessionStorage.getItem('user'));
        }
        return null;
    }

    getCustomer() {
        if (sessionStorage.getItem('customer')) {
            return JSON.parse(sessionStorage.getItem('customer'));
        }
        return null;
    }

    setCustomer(newCustomer) {
        sessionStorage.setItem('customer', JSON.stringify(newCustomer));
    }

    login(username, password) {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        let authUrl = Config.REACT_APP_API_ROOT_URL + "/users/authenticate";
        return axios({
            method: 'post',
            url: authUrl,
            data: {
                username: username,
                password: password
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res && res.data) {
                sessionStorage.setItem('token', res.data.tmp.token);
                let user = res.data.tmp.user;
                sessionStorage.setItem('user', JSON.stringify(user));
                let customer = user.customers.find(c => c.c_id === user.default_customer);
                sessionStorage.setItem('customer', JSON.stringify(customer));
                return res;
            }

        }).catch((err) => {
            throw new Error("Invalid credentials");
        })
    }

    UIPermissions(type) {
        let list = [
            { 'displayValue': 'Dashboard', 'internalValue': 'UI_DASHBOARD' },
            { 'displayValue': 'Download Content', 'internalValue': 'UI_DOWNLOAD' }
        ];
        if (type === 'cust_admin' || type === 'delectrik_admin') {
            list.push({ 'displayValue': 'Users', 'internalValue': 'UI_USERS' });
        }
        if (type === 'delectrik_admin') {
            list.push({ 'displayValue': 'Customers', 'internalValue': 'UI_CUSTOMERS' })
        }
        return list;
    }

    destroyToken() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('devices');
        sessionStorage.removeItem('state');
        sessionStorage.removeItem('version');
        this.user = null;
    }

    logout() {
        this.destroyToken();
    }

    checkUserAccess(routes, replace) {
        if (sessionStorage.getItem('user')) {
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (!user) {
                // redirect to login if not
                replace('/login');
            }

            // // get all roles available for this route
            // const routeRoles = _.chain(routes)
            //     .filter(item => item.authorize) // access to custom attribute
            //     .map(item => item.authorize)
            //     .flattenDeep()
            //     .value();

            // // compare routes with user data
            // if (user) {
            //     if (_.intersection(routeRoles, user.ui_permissions).length === 0) {
            //         this.hasAccess = false;
            //         replace('/not-authorized');
            //     }
            // }
        }
    }
}

let xauth = window.AuthStore = new AuthStore();
export default xauth;