import React from 'react';
import { smoothlyMenu } from '../layouts/Helpers';
import { Link } from 'react-router-dom';
import pubsub from 'pubsub-js';
import $ from 'jquery';
import AuthStore from '../../stores/AuthStore';

class TopHeader extends React.Component {

    constructor() {
        super();
        this.subscriptionToken = null;
    }

    componentWillMount() {
        let me = this;
        this.subscriptionToken = pubsub.subscribe('CUSTOMER_CHANGED', function (topic, data) {
            me.forceUpdate();
        })
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.subscriptionToken);
    }

    toggleNavigation(e) {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }

    onChange(e) {
        pubsub.publish('GLOBAL_SEARCH', e.target.value);
    }

    renderChangeCustomer() {
        if (AuthStore.getUser().type === 'delectrik_admin') {
            let current_customer = AuthStore.getCustomer();
            return <li>
                <Link to="/changeCustomer" className="fa fa-at"> {current_customer.c_name}</Link>
            </li>;
        }
        return null;
    }

    render() {
        return (
            <div className="row border-bottom">
                <nav className="navbar navbar-static-top white-bg" style={{ marginBottom: 0 }}>
                    <div className="navbar-header">
                        <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " onClick={this.toggleNavigation}><i className="fa fa-bars"></i> </a>
                        <form className="navbar-form-custom" action="search_results.html">
                            <div className="form-group">
                                <input type="text" placeholder="Search for something..." onChange={this.onChange.bind(this)} className="form-control" name="top-search" id="top-search" />
                            </div>
                        </form>
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                        {this.renderChangeCustomer()}
                        <li>
                            <Link to="/changePassword" className="fa fa-sign-out"> Change Password</Link>
                        </li>
                        <li>
                            <Link to="/login" className="fa fa-sign-out"> Log out</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default TopHeader